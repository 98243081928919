import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import firebase from 'firebase/compat/app'
import { database } from '../../firebaseApp'
import { AppDispatch } from '../store'

export interface Invite {
  id: string
  accountId: string
  createdAt: {
    seconds: number
    nanoseconds: number
  }
  status: string
  email: string
  validityCode: number
  invitedBy: string
  role: string
  name?: string
  profilePicture?: string
}
interface InviteState {
  invites: Invite[]
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null | undefined
}
const initialState: InviteState = {
  invites: [],
  loading: 'idle',
  error: null,
}

export const fetchInvites = createAsyncThunk(
  'invite/fetchInvites',
  async ({ dispatch }: { dispatch: AppDispatch }) => {
    try {
      const currentUserId = firebase.auth().currentUser?.uid
      database
        .collection('invites')
        .where('invitedBy', '==', currentUserId)
        .onSnapshot((querySnapshot) => {
          const invites = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          dispatch(setInvites(invites))
        })
    } catch (error) {
      console.log(error)
    }
  },
)

const inviteSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    clearInvites: (state) => {
      state.invites = []
    },
    setInvites: (state, action) => {
      state.invites = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvites.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(fetchInvites.fulfilled, (state) => {
        state.loading = 'succeeded'
      })
      .addCase(fetchInvites.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = action.error.message
      })
  },
})
export const { clearInvites, setInvites } = inviteSlice.actions
export default inviteSlice.reducer
