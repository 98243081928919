import React, { useEffect, useState } from 'react'
import {
  // Avatar,
  Card,
  CardBody,
  // CardFooter,
  Button,
  Typography,
  Chip,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '../../store/store'
// import {
//   setCurrentProject,
//   // addMember
// } from '../../store/features/projectSlice'
// import { IProject } from '../../interfaces'
import Toast from '../../components/Toast/Toast'
// import UserProfileAvatar from '../UserProfileAvatar'
// import AddDialog from './AddDialog'
import { useNavigate } from 'react-router-dom'
// import CustomTooltip from '../Tooltip/Tooltip'
import {
  archiveKeyword,
  fetchKeywordSubCollections,
  getKeywordSubData,
  Iincident,
  IKeyword,
} from '../../store/features/keywordSlice'
import { Timestamp } from 'firebase/firestore'
// import ConfirmationDialog from '../TranscriptsList/ConfirmationDialog'
import ArchiveConfirmDialog from './ArchiveConfirmDialog'
// import { customEvent } from '../../utils/customHooks'

// interface IProps {
//   project: IProject
//   handleEdit: (id: string, name: string, description?: string) => void
//   handleArchive: (id: string) => void
//   roles: { [key: string]: string[] }
// }

export default function ProjectCard({
  keyword,
}: {
  keyword: IKeyword
}): React.JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [incident, setIncident] = useState<Iincident[] | undefined>()
  // const dispatch = useAppDispatch()
  // const { members, currentAccount } = useAppSelector((store) => store.account)
  // const { userData } = useAppSelector((store) => store.user)
  const { roles } = useAppSelector((store) => store.keyword)
  // const [openAddDialog, setOpenAddDialog] = useState(false)
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false)
  // const [projectSettingRole, setProjectSettingRole] = useState<string[]>([])
  // const [projectMemberRole, setProjectMemberRole] = useState<string[]>([])
  console.log(roles, 'roles')
  useEffect(() => {
    const fetchData = async () => {
      const getKeywordSubCollections = await getKeywordSubData(keyword.id!)
      if (getKeywordSubCollections) {
        setIncident(getKeywordSubCollections)
      }
    }

    fetchData() // Call the async function
  }, [keyword])
  // const userAccount = currentAccount.users
  //   ? currentAccount.users[String(userData.id)]
  //   : null

  useEffect(() => {
    if (keyword.id) {
      fetchKeywordSubCollections(keyword.id!, dispatch)
    }
  }, [keyword])
  // const handleOpenAdd = (): void => {
  //   setOpenAddDialog(!openAddDialog)
  // }

  const handleOpenArchive = (): void => {
    setOpenArchiveDialog(!openArchiveDialog)
  }

  // const filteredMembers = members.filter((member) => {
  //   return project.users[member.id!]
  // })

  // const detailedFilteredMembers = filteredMembers.map((member) => {
  //   return {
  //     id: member.id!,
  //     email: member.email!,
  //     name: member.name!,
  //     role: project.users[member.id!].role,
  //   }
  // })

  // const unfilteredMembers = members.filter((member) => {
  //   return !project.users[member.id!]
  // })

  // useEffect(() => {
  //   const currentUser = project.users[user.uid!]
  //   const settingRole = roles[currentUser.role]?.['projectSetting']
  //   const memberRole = roles[currentUser.role]?.['member']
  //   setProjectSettingRole(settingRole)
  //   setProjectMemberRole(memberRole)
  // }, [project, roles, user])

  const handleCopy = (id: string): void => {
    const domain = window.location.origin
    navigator.clipboard.writeText(`${domain}/keyword/${id}`)
    Toast({ text: t && t('transcripts.copyConfirm'), variant: 'success' })
  }

  const handleArchive = () => {
    if (keyword.id) {
      archiveKeyword(keyword.id)
    }
  }

  // const handleAddMember = async (
  //   projectId: string,
  //   memberId: string,
  //   role: string,
  // ): Promise<void> => {
  //   await addMember(projectId, memberId, role)
  //   customEvent('project_collaborator_invited', {
  //     accountId: currentAccount.id,
  //     projectId: projectId,
  //     userRole: role,
  //     user_userId: userData.id,
  //   })
  // }

  return (
    <>
      <Card
        placeholder={undefined}
        className="mt-7 w-[30rem] border h-64 hover:shadow-lg"
        onClick={() => {
          // dispatch(setCurrentProject(project))
          navigate('/keyword/' + keyword.id)
        }}
      >
        <CardBody placeholder={undefined} className="h-44">
          <div className="flex justify-between items-center">
            <Typography
              placeholder="Title"
              className="text-2xl text-blue-gray-900 font-medium"
            >
              {/* {project.name} */}
              {keyword.title}
            </Typography>
            <Menu placement="bottom-end" allowHover={true}>
              <MenuHandler>
                <Button
                  placeholder={undefined}
                  variant="text"
                  className="p-2 min-w-0 h-auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-more-vertical"
                  >
                    <circle cx="12" cy="11" r="1"></circle>
                    <circle cx="12" cy="6" r="1"></circle>
                    <circle cx="12" cy="16" r="1"></circle>
                  </svg>
                </Button>
              </MenuHandler>
              <MenuList placeholder={undefined}>
                {/* {projectSettingRole?.includes('edit') && ( */}
                <MenuItem
                  placeholder={undefined}
                  onClick={(event) => {
                    event.stopPropagation()
                    navigate('/keyword/' + keyword.id + '/edit')
                  }}
                >
                  <Typography
                    placeholder="Edit"
                    className="text-lstnBlueGray-500 font-medium text-sm"
                  >
                    {t('projects.edit')}
                  </Typography>
                </MenuItem>
                {/* )} */}
                {/* {projectSettingRole?.includes('archive') &&
                  project.status !== 'archived' && ( */}
                <MenuItem
                  placeholder={undefined}
                  onClick={(event) => {
                    event.stopPropagation()
                    // setConfirmDelete(!conformDelete)
                    handleOpenArchive()
                  }}
                >
                  <Typography
                    placeholder="Archive"
                    className="text-lstnBlueGray-500 font-medium text-sm"
                  >
                    {t('projects.archive')}
                  </Typography>
                </MenuItem>
                {/* )} */}
                <MenuItem
                  placeholder={undefined}
                  onClick={(event) => {
                    event.stopPropagation()
                    handleCopy(keyword?.id!)
                  }}
                >
                  <Typography
                    placeholder="CopyLink"
                    className="text-lstnBlueGray-500 font-medium text-sm"
                  >
                    {t('projects.copyLink')}
                  </Typography>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
          <div className="flex gap-2 items-center mt-3 mb-3">
            {/* {project.status === 'active' ? ( */}
            <Chip
              value={t('projects.active')}
              variant="outlined"
              size="sm"
              className="text-xs text-blue-gray-900"
            />
            {/* // ) : project.status === 'archived' ? ( */}
            {/* <Chip
                value={t('projects.archived')}
                variant="outlined"
                size="sm"
                className="text-xs text-blue-gray-400 border-blue-gray-300"
              /> */}
            {/* ) : null} */}
          </div>
          <Typography
            placeholder="Add Description"
            className="text-base font-normal text-lstnBlueGray-500 text-start truncate-lines"
          >
            Tracked since:{' '}
            {keyword?.trackedSince
              ? new Timestamp(
                  keyword.trackedSince.seconds,
                  keyword.trackedSince.nanoseconds,
                )
                  .toDate()
                  .toLocaleDateString('en-US', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  })
              : 'unknown'}
            <br />
            Incidents last 30 days: {incident ? incident.length : 0} incidents
          </Typography>
        </CardBody>
        {/* <CardFooter placeholder={undefined} className="py-0">
          <div className="flex">
            <div className="flex items-center -space-x-4">
              <CustomTooltip
             
                content={
                  <div className="items-center justify-center flex flex-col">
                    <Typography
                      color="white"
                      className="font-medium"
                      placeholder={undefined}
                    >
                   
                      Alice
                    </Typography>
                    <Typography
                      variant="small"
                      color="white"
                      className="font-normal opacity-80"
                      placeholder={undefined}
                    >
                 
                      example.com
                    </Typography>
                  </div>
                }
                className="bg-lstnBlueGray-400"
              >
           
                <Avatar
                  className="w-12 h-12 2xl:w-14 2xl:h-14"
                  variant="circular"
                  src="/Avatar.png"
                  alt="tania andrew"
                  placeholder={undefined}
                />
              </CustomTooltip>
              <CustomTooltip
              
                content={
                  <div className="items-center justify-center flex flex-col">
                    <Typography
                      color="white"
                      className="font-medium"
                      placeholder={undefined}
                    >
                 
                      Alice
                    </Typography>
                    <Typography
                      variant="small"
                      color="white"
                      className="font-normal opacity-80"
                      placeholder={undefined}
                    >
                    
                      example.com
                    </Typography>
                  </div>
                }
                className="bg-lstnBlueGray-400"
              >
               
                <Avatar
                  className="w-12 h-12 2xl:w-14 2xl:h-14"
                  variant="circular"
                  src="/Avatar1.png"
                  alt="tania andrew"
                  placeholder={undefined}
                />
              </CustomTooltip>
             
            </div>
         
            <Typography
              className="text-sm font-medium text-lstnBlue-500 cursor-pointer text-lstnBlueGray-900 flex items-center justify-start"
              placeholder="Add New"
             
            >
              <div className="inline-block justify-center ml-5 bg-lstnBlueGray-500 border-lstnBlueGray-500 text-white w-3 h-3 p-0 rounded-full text-center relative mr-1">
                <svg width="12" height="12" aria-hidden="true">
                  <use xlinkHref="#icon-pluss" />
                </svg>
              </div>
              {t('projects.addNew')}
            </Typography>
         
          </div>
        </CardFooter> */}
      </Card>
      {/* <AddDialog
        open={openAddDialog}
        handleOpen={handleOpenAdd}
        project={project}
        members={unfilteredMembers}
        joinedMembers={detailedFilteredMembers}
        addMember={handleAddMember}
        accountRole={userAccount?.role!}
        source="project-card"
        userId={userData.id!}
      /> */}
      <ArchiveConfirmDialog
        projectName={keyword.title}
        projectId={keyword.id!}
        handleArchive={handleArchive}
        handleOpenArchive={handleOpenArchive}
        openArchiveDialog={openArchiveDialog}
        // accountId={}
        // source="project-card"
        // userId={userData.id!}
      />
      {/* <AddKeyword
        openKeywordDialog={openKeywordDialog}
        handleKeywordDialog={handleKeywordDialog}
        dialogType="edit"
        keywordData={keyword}
      /> */}
    </>
  )
}

export const ProjectCardSkeleton = () => {
  return (
    <>
      <Card
        placeholder={undefined}
        className="mt-7 w-[30rem] border h-64 hover:shadow-lg animate-pulse"
      >
        <CardBody placeholder={undefined} className="h-44">
          <div className="flex justify-between items-center">
            <Typography
              placeholder="Title"
              className=" h-4 w-14 rounded-md bg-gray-300"
            >
              {/* {keyword.title} */}
              &nbsp;
            </Typography>
            <Menu placement="bottom-end" allowHover={true}>
              <MenuHandler>
                <Button
                  placeholder={undefined}
                  variant="text"
                  className="!py-0 min-w-0 h-auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-more-vertical"
                  >
                    <circle cx="12" cy="11" r="1"></circle>
                    <circle cx="12" cy="6" r="1"></circle>
                    <circle cx="12" cy="16" r="1"></circle>
                  </svg>
                </Button>
              </MenuHandler>
              <MenuList placeholder={undefined}>
                {/* {projectSettingRole?.includes('edit') && ( */}
                <MenuItem placeholder={undefined}>
                  <Typography
                    placeholder="Edit"
                    className="mb-2 h-2 w-20 rounded-full bg-gray-300"
                  >
                    &nbsp;
                  </Typography>
                </MenuItem>
                {/* )} */}
                {/* {projectSettingRole?.includes('archive') &&
                  project.status !== 'archived' && ( */}
                <MenuItem placeholder={undefined}>
                  <Typography
                    placeholder="Archive"
                    className="mb-2 h-2 w-20 rounded-full bg-gray-300"
                  >
                    &nbsp;
                  </Typography>
                </MenuItem>
                {/* )} */}
                <MenuItem placeholder={undefined}>
                  <Typography
                    placeholder="CopyLink"
                    className="mb-2 h-2 w-20 rounded-full bg-gray-300"
                  >
                    &nbsp;
                  </Typography>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
          <div className="flex gap-2 items-center mt-3 mb-3">
            {/* {project.status === 'active' ? ( */}
            <Typography
              as="div"
              variant="h1"
              className="mb-2 h-4 w-20 rounded-full bg-gray-300"
              placeholder={undefined}
            >
              &nbsp;
            </Typography>
          </div>
          <div>
            <Typography
              as="div"
              variant="paragraph"
              className="mb-2 h-2 w-48 rounded-full bg-gray-300"
              placeholder={undefined}
            >
              &nbsp;
            </Typography>

            <Typography
              as="div"
              variant="paragraph"
              className="mb-2 h-2 w-48 rounded-full bg-gray-300"
              placeholder={undefined}
            >
              &nbsp;
            </Typography>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
