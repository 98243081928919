import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { ChangeEventHandler, useState } from 'react'
import * as Yup from 'yup'
// import Button from '../Button/Button'
import { Button, Input, Textarea, Typography } from '@material-tailwind/react'
import Selector from '../SelectInput/Select'
import AvatarLogo from '../../icons/AccountLogo.svg'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { storage } from '../../firebaseApp'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../store/store'
import Toast from '../Toast/Toast'
import firebase from 'firebase/compat/app'
import axios from 'axios'

const AdInfringementWithImage = () => {
  const [status, setStatus] = useState<string>('identified')
  const [imageURL, setImageURL] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()

  const { userData } = useAppSelector((store) => store.user)
  const [errorMessage, setErrorMessage] = useState('')
  const [keywordId, setKeywordId] = useState('')
  const handleSubmit = async (values: any, { resetForm }: any) => {
    const { adCountries, ...rest } = values
    const token = await firebase?.auth()?.currentUser?.getIdToken()
    await axios
      .post(
        `${process.env.REACT_APP_FIREBASE_API}/addInfringementDetails`,
        {
          data: {
            operation: 'ad-infringement-with-image',
            keywordId: keywordId,
            imageURL: imageURL,
            status: status,
            countries: adCountries,
            ad_domain_displayed: rest.adDomain,
            ad_subtitle: rest.adSubtitle,
            ad_title: rest.adTitle,
            advertiserId: rest.advertiserId,
            advertiser_name: rest.advertiserName,
            url: rest.url,
            adCreativeId: rest.adCreativeId,
            advertiserWebsite: rest.advertiserWebsite,
            adTrackingUrl: rest.adTrackingUrl,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Expose-Headers': 'Content-Disposition',
          },
        },
      )
      .then((res) => {
        console.log(res.data.message)
        setImageURL('')
        setKeywordId('')
        Toast({
          // title: 'Document created successfully!',
          title: res.data.message,
          variant: 'success',
          options: { autoClose: 5000 },
        })
      })
      .catch((error: any) => {
        setImageURL('')
        setKeywordId('')
        console.log(error)
        Toast({
          title: 'Creation Failed!',
          variant: 'error',
          options: { autoClose: 5000 },
        })
      })
    resetForm()
  }

  const validationSchema = Yup.object({
    adCreativeId: Yup.string(),
    adCountries: Yup.string().required('Ad Countries are required'),
    adDomain: Yup.string()
      .url('Must be a valid URL')
      .required('Ad Domain is required'),
    advertiserWebsite: Yup.string().url('Must be a valid URL'),
    adTrackingUrl: Yup.string().url('Must be a valid URL'),
    adTitle: Yup.string().required('Ad Title is required'),
    adSubtitle: Yup.string().required('Ad Subtitle is required'),
    advertiserId: Yup.string().required('Advertiser ID is required'),
    advertiserName: Yup.string().required('Advertiser Name is required'),
    url: Yup.string().url('Must be a valid URL').required('URL is required'),
  })
  const allStatuses = [
    { value: 'reported', label: 'Reported' },
    { value: 'repeated', label: 'Repeated' },
    { value: 'resolved', label: 'Resolved' },
    { value: 'identified', label: 'Identified' },
    { value: 'not an incident', label: 'Not an incident' },
  ]
  const handleStatusChange = async (
    value: string | undefined,
  ): Promise<void> => {
    const st = value || ''
    setStatus(st as string)
  }

  const handlePictureChange = async (event: {
    target: { files: File[] }
  }): Promise<void> => {
    setIsLoading(true)
    try {
      const file = event.target.files[0]
      const name = `${file.name}_${Date.now()}`
      const storageRef = ref(storage, `${keywordId}/${name}`)
      const metadata = {
        contentType: 'image/jpeg',
      }
      await uploadBytes(storageRef, file, metadata)
      const url = await getDownloadURL(storageRef)
      setImageURL(url)
      // console.log(url)
    } catch (error) {
      console.error('Error uploading file:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleUploadButtonClick = (): void => {
    if (keywordId === '') {
      setErrorMessage('Please add a keyword ID first')
      return
    } else {
      setErrorMessage('')
    }
    document.getElementById('contained-button-file')?.click()
  }

  return (
    <div className="flex flex-row flex-wrap items-center gap-6">
      <Formik
        initialValues={{
          adCreativeId: '',
          adCountries: '',
          adDomain: '',
          advertiserWebsite: '',
          adTrackingUrl: '',
          adTitle: '',
          adSubtitle: '',
          advertiserId: '',
          advertiserName: '',
          url: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="w-96 space-y-4">
            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Ad Creative Id
              </label>
              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="1234567890"
                name="adCreativeId"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="adCreativeId"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Keyword Id
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="1234567890"
                name="keywordId"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                value={keywordId}
                onChange={(e: {
                  target: { value: React.SetStateAction<string> }
                }) => setKeywordId(e.target.value)}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="keywordId"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Advertiser ID
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="1234567890"
                name="advertiserId"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="advertiserId"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>

            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Advertiser Name
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="---"
                name="advertiserName"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="advertiserName"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Status
              </label>
              <Selector
                selectClassName="border border-blue-gray-100 rounded-lg uppercase font-semibold w-full"
                className="w-full"
                options={allStatuses}
                selectedValue={status}
                onChange={handleStatusChange}
                children={undefined}
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Ad Countries
              </label>

              <Field
                as={Textarea}
                size="md"
                type="text"
                placeholder="e.g., US or United States"
                name="adCountries"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="adCountries"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>

            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Ad Domain Displayed
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="e.g., https://www.example.com"
                name="adDomain"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="adDomain"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Advertiser Website
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="e.g., https://www.example.com"
                name="advertiserWebsite"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="advertiserWebsite"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Ad Tracking Url
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="e.g., https://www.example.com"
                name="adTrackingUrl"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="adTrackingUrl"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>

            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Ad Title
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="Ad Title"
                name="adTitle"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="adTitle"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>
            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                Ad Subtitle
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="ad Subtitle"
                name="adSubtitle"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="adSubtitle"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>

            <div className="flex flex-col mb-4">
              <label className="text-sm font-medium text-wellBlueGray-900">
                URL
              </label>

              <Field
                as={Input}
                size="md"
                type="text"
                placeholder="https://www.example.com"
                name="url"
                className=" !border-lstnBlueGray-100 focus:!border-gray-900 text-blue-gray-400 placeholder:opacity-100"
                labelProps={{
                  className: 'hidden',
                }}
                crossOrigin={undefined}
                data-cy="data-account-company-website-url"
              />
              <ErrorMessage
                name="url"
                component="p"
                className="text-red-700 text-xs mt-1"
              />
            </div>
            <div>
              {keywordId === '' && errorMessage ? (
                <p className="text-red-700 text-xs font-normal text-start">
                  {errorMessage}
                </p>
              ) : (
                ''
              )}
              <Button
                type="submit"
                disabled={isSubmitting}
                className="!bg-lstnGreen-500 w-full mt-2 py-2 text-white rounded-lg flex justify-center"
                placeholder={undefined}
              >
                {isSubmitting ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <div className="flex-col items-center !w-fit md:pl-6 justify-center relative">
        <Typography
          className="text-base font-medium text-lstnBlueGray-900 mb-2"
          placeholder={undefined}
        >
          uploaded Images
        </Typography>
        <div className="mb-3 flex justify-center">
          {imageURL ? (
            <img
              src={imageURL?.toString() ?? undefined}
              width="74px"
              height="74px"
              className="rounded-full"
            />
          ) : (
            <img src={AvatarLogo} width="74px" height="74px" />
          )}
        </div>
        <div className="flex justify-center">
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            hidden
            onChange={
              handlePictureChange as unknown as ChangeEventHandler<HTMLInputElement>
            }
          />

          <label htmlFor="contained-button-file" className="block">
            <Button
              placeholder={undefined}
              variant="outlined"
              size="sm"
              onClick={handleUploadButtonClick}
              loading={isLoading ? isLoading : !userData}
            >
              {t('uploadButton.uploadAvatar')}
            </Button>
          </label>
        </div>
      </div>
    </div>
  )
}

export default AdInfringementWithImage
