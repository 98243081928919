import { Button, Input, Typography } from '@material-tailwind/react'
import React, { ChangeEventHandler, useEffect, useState } from 'react'
import Selector from '../SelectInput/Select'
import AvatarLogo from '../../icons/AccountLogo.svg'
import { storage } from '../../firebaseApp'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { useAppSelector } from '../../store/store'
import { useTranslation } from 'react-i18next'
import firebase from 'firebase/compat/app'
import axios from 'axios'
import Toast from '../Toast/Toast'

const InfringementWithImageDetail = () => {
  const [status, setStatus] = useState<string>('identified')
  const [imageURL, setImageURL] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [keywordId, setKeywordId] = useState('')
  const [adId, setAdId] = useState<string>('')
  const [adTrackingUrl, setAdTrackingUrl] = useState<string>('')
  const [adDomainDisplayed, setAdDomainDisplayed] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState('')
  const [country, setCountry] = useState('')
  const [loading, setLoading] = React.useState(false)
  const { userData } = useAppSelector((store) => store.user)
  const { t } = useTranslation()

  const [btnDisabled, setBtnDisabled] = useState({
    country: true,
    keywordId: true,
    adId: true,
  })

  useEffect(() => {
    setBtnDisabled({
      country: country.length < 2,
      keywordId: keywordId.length < 2,
      adId: adId.length < 2,
    })
  }, [country, keywordId, adId])

  const allStatuses = [
    { value: 'reported', label: 'Reported' },
    { value: 'repeated', label: 'Repeated' },
    { value: 'resolved', label: 'Resolved' },
    { value: 'identified', label: 'Identified' },
    { value: 'not an incident', label: 'Not an incident' },
  ]

  const handleStatusChange = async (
    value: string | undefined,
  ): Promise<void> => {
    const st = value || ''
    setStatus(st as string)
  }
  const handlePictureChange = async (event: {
    target: { files: File[] }
  }): Promise<void> => {
    setIsLoading(true)
    try {
      const file = event.target.files[0]
      const name = `${file.name}_${Date.now()}`
      const storageRef = ref(storage, `${keywordId}/${name}`)
      const metadata = {
        contentType: 'image/jpeg',
      }
      await uploadBytes(storageRef, file, metadata)
      const url = await getDownloadURL(storageRef)
      setImageURL(url)
      // console.log(url)
    } catch (error) {
      console.error('Error uploading file:', error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleUploadButtonClick = (): void => {
    if (keywordId === '') {
      setErrorMessage('Please add a keyword ID first')
      return
    } else {
      setErrorMessage('')
    }
    document.getElementById('contained-button-file')?.click()
  }

  const updateInfringementDetails = async () => {
    // if (advertiserId !== '' && advertiserName !== '') {
    setLoading(true)
    const token = await firebase?.auth()?.currentUser?.getIdToken()
    await axios
      .post(
        `${process.env.REACT_APP_FIREBASE_API}/addInfringementDetails`,
        {
          data: {
            operation: 'infringement-with-image-details',
            keywordId: keywordId,
            country: country,
            imageURL: imageURL,
            status: status,
            ad_id: adId,
            adTrackingUrl: adTrackingUrl,
            ad_domain_displayed: adDomainDisplayed,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Expose-Headers': 'Content-Disposition',
          },
        },
      )
      .then((res) => {
        setKeywordId('')
        setAdId('')
        setImageURL('')
        setCountry('')
        setAdTrackingUrl('')
        setAdDomainDisplayed(' ')
        Toast({
          title: res.data.message,
          variant: 'success',
          options: { autoClose: 5000 },
        })
        setLoading(false)
      })
      .catch((error: any) => {
        setLoading(false)
        console.log(error)
        Toast({
          title: 'Creation Failed!',
          variant: 'error',
          options: { autoClose: 5000 },
        })
      })
    // }
  }

  return (
    <div className="flex flex-row flex-wrap items-center gap-6">
      <div className="w-96 space-y-4">
        <div className="mb-1 flex flex-col">
          <Typography
            placeholder={undefined}
            variant="h6"
            className="mb-1 text-sm font-medium text-wellBlueGray-900"
          >
            Status
          </Typography>
          <Selector
            selectClassName="border border-blue-gray-100 rounded-lg uppercase font-semibold w-full"
            className="w-full"
            options={allStatuses}
            selectedValue={status}
            onChange={handleStatusChange}
            children={undefined}
          />
        </div>
        <div className="mb-1 flex flex-col my-3">
          <Typography
            placeholder={undefined}
            variant="h6"
            className="mb-1 text-sm font-medium text-wellBlueGray-900"
          >
            Ad Creative Id
          </Typography>
          <Input
            autoFocus
            type="text"
            size="lg"
            value={adId}
            placeholder="e.g., 1234567890"
            className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
            labelProps={{
              className: 'hidden',
            }}
            style={{ borderRadius: '8px' }}
            onChange={(e) => setAdId(e.target.value)}
            crossOrigin={undefined}
          />
        </div>
        <div className="mb-1 flex flex-col my-3">
          <Typography
            placeholder={undefined}
            variant="h6"
            className="mb-1 text-sm font-medium text-wellBlueGray-900"
          >
            Ad Tracking Url
          </Typography>
          <Input
            autoFocus
            type="text"
            size="lg"
            value={adTrackingUrl}
            placeholder="e.g., 1234567890"
            className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
            labelProps={{
              className: 'hidden',
            }}
            style={{ borderRadius: '8px' }}
            onChange={(e) => setAdTrackingUrl(e.target.value)}
            crossOrigin={undefined}
          />
        </div>
        <div className="mb-1 flex flex-col my-3">
          <Typography
            placeholder={undefined}
            variant="h6"
            className="mb-1 text-sm font-medium text-wellBlueGray-900"
          >
            Ad Domain Displayed
          </Typography>
          <Input
            autoFocus
            type="text"
            size="lg"
            value={adDomainDisplayed}
            placeholder="e.g., 1234567890"
            className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
            labelProps={{
              className: 'hidden',
            }}
            style={{ borderRadius: '8px' }}
            onChange={(e) => setAdDomainDisplayed(e.target.value)}
            crossOrigin={undefined}
          />
        </div>
        <div className="mb-1 flex flex-col">
          <Typography
            placeholder={undefined}
            variant="h6"
            className="mb-1 text-sm font-medium text-wellBlueGray-900"
          >
            Country
          </Typography>
          <Input
            autoFocus
            type="text"
            size="lg"
            value={country}
            placeholder="e.g., US or United States"
            className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
            labelProps={{
              className: 'hidden',
            }}
            style={{ borderRadius: '8px' }}
            onChange={(e: {
              target: { value: React.SetStateAction<string> }
            }) => setCountry(e.target.value)}
            crossOrigin={undefined}
          />
        </div>
        <div className="mb-1 flex flex-col my-3">
          <Typography
            placeholder={undefined}
            variant="h6"
            className="mb-1 text-sm font-medium text-wellBlueGray-900"
          >
            Keyword Id
          </Typography>
          <Input
            autoFocus
            type="text"
            size="lg"
            value={keywordId}
            placeholder="e.g., 1234567890"
            className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
            labelProps={{
              className: 'hidden',
            }}
            style={{ borderRadius: '8px' }}
            onChange={(e) => setKeywordId(e.target.value)}
            crossOrigin={undefined}
          />
        </div>

        <div>
          {/* Conditional Message */}
          {keywordId === '' && errorMessage ? (
            <p className="text-red-700 text-xs font-normal text-start">
              {errorMessage}
            </p>
          ) : (
            ''
          )}
          <Button
            variant="filled"
            className="!bg-lstnGreen-500 w-full mt-2 flex justify-center items-center"
            placeholder={undefined}
            onClick={updateInfringementDetails}
            loading={loading}
            disabled={
              btnDisabled.country || btnDisabled.keywordId || btnDisabled.adId
            }
          >
            <span>Save</span>
          </Button>
        </div>
      </div>
      <div className="flex-col items-center !w-fit md:pl-6 justify-center relative">
        <Typography
          className="text-base font-medium text-lstnBlueGray-900 mb-2"
          placeholder={undefined}
        >
          uploaded Images
        </Typography>
        <div className="mb-3 flex justify-center">
          {imageURL ? (
            <img
              src={imageURL?.toString() ?? undefined}
              width="74px"
              height="74px"
              className="rounded-full"
            />
          ) : (
            <img src={AvatarLogo} width="74px" height="74px" />
          )}
        </div>
        <div className="flex justify-center">
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            hidden
            onChange={
              handlePictureChange as unknown as ChangeEventHandler<HTMLInputElement>
            }
          />

          <label htmlFor="contained-button-file" className="block">
            <Button
              placeholder={undefined}
              variant="outlined"
              size="sm"
              onClick={handleUploadButtonClick}
              loading={isLoading ? isLoading : !userData}
            >
              {t('uploadButton.uploadAvatar')}
            </Button>
          </label>
        </div>
      </div>
    </div>
  )
}

export default InfringementWithImageDetail
