import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Typography,
  Input,
  Button,
  Radio,
} from '@material-tailwind/react'
import React, { ChangeEventHandler, useEffect, useState } from 'react'
import Toast from '../components/Toast/Toast'
import firebase from 'firebase/compat/app'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
// import ImagePlaceholderSkeleton from '../components/ImagePlaceholderSkeleton'
// import UserProfileAvatar from '../components/UserProfileAvatar'
import { useAppSelector } from '../store/store'
import AvatarLogo from '../icons/AccountLogo.svg'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { storage } from '../firebaseApp'
import AdInfringementWithImage from '../components/AdminDashboard'
import InfringementWithImageDetail from '../components/AdminDashboard/infringementWithImageDetail'

const AdminPanel = () => {
  const [activeTab, setActiveTab] = React.useState('add-Advertiser')
  const [loading, setLoading] = React.useState(false)
  const [advertiserId, setAdvertiserId] = React.useState<string>('')
  const [advertiserName, setAdvertiserName] = React.useState('')

  const [country, setCountry] = React.useState('')
  const [keywordId, setKeywordId] = React.useState('')
  const [incidentId, setIncidentId] = React.useState('')
  const [imageURL, setImageURL] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState('')

  const [adTrackingUrl, setAdTrackingUrl] = useState<string>('')
  const [adDomainDisplayed, setAdDomainDisplayed] = useState<string>('')

  const [btnDisabled, setBtnDisabled] = useState({
    country: true,
    keywordId: true,
    incidentId: true,
  })

  const [operation, setOperation] = useState('image-only')

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setOperation(event.target.value)
  }

  useEffect(() => {
    setBtnDisabled({
      country: country.length < 2,
      keywordId: keywordId.length < 2,
      incidentId: incidentId.length < 2,
    })
  }, [country, keywordId, incidentId])

  // const { currentAccount } = useAppSelector((store) => store.account)
  const { userData } = useAppSelector((store) => store.user)
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()

  const handleSubmit = async () => {
    if (advertiserId !== '' && advertiserName !== '') {
      setLoading(true)
      const token = await firebase?.auth()?.currentUser?.getIdToken()
      await axios
        .post(
          `${process.env.REACT_APP_FIREBASE_API}/addNewAdvertiser`,
          {
            data: {
              advertiserId: advertiserId,
              advertiserName: advertiserName,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Access-Control-Expose-Headers': 'Content-Disposition',
            },
          },
        )
        .then(() => {
          setAdvertiserId('')
          setAdvertiserName('')
          Toast({
            title: 'Advertiser Created!',
            variant: 'success',
            options: { autoClose: 5000 },
          })
          setLoading(false)
        })
        .catch((error: any) => {
          setAdvertiserId('')
          setAdvertiserName('')
          console.log(error)
          Toast({
            title: 'Creation Failed!',
            variant: 'error',
            options: { autoClose: 5000 },
          })
          setLoading(false)
        })
    }
  }

  const handlePictureChange = async (event: {
    target: { files: File[] }
  }): Promise<void> => {
    setIsLoading(true)
    try {
      const file = event.target.files[0]
      const name = `${file.name}_${Date.now()}`
      const storageRef = ref(storage, `${keywordId}/${name}`)
      const metadata = {
        contentType: 'image/jpeg',
      }
      await uploadBytes(storageRef, file, metadata)
      const url = await getDownloadURL(storageRef)
      setImageURL(url)
      // console.log(url)
    } catch (error) {
      console.error('Error uploading file:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUploadButtonClick = (): void => {
    if (keywordId === '') {
      setErrorMessage('Please add a keyword ID first')
      return
    } else {
      setErrorMessage('')
    }
    document.getElementById('contained-button-file')?.click()
  }

  const updateInfringementDetails = async () => {
    setLoading(true)
    const token = await firebase?.auth()?.currentUser?.getIdToken()
    await axios
      .post(
        `${process.env.REACT_APP_FIREBASE_API}/addInfringementDetails`,
        {
          data: {
            operation: 'image-only',
            keywordId: keywordId,
            country: country,
            imageURL: imageURL,
            incidentId: incidentId,
            adTrackingUrl: adTrackingUrl,
            ad_domain_displayed: adDomainDisplayed,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Expose-Headers': 'Content-Disposition',
          },
        },
      )
      .then(() => {
        setKeywordId('')
        setCountry('')
        setIncidentId('')
        setImageURL('')
        setAdTrackingUrl('')
        setAdDomainDisplayed('')
        Toast({
          title: 'Document updated successfully!',
          variant: 'success',
          options: { autoClose: 5000 },
        })
        setLoading(false)
      })
      .catch((error: any) => {
        console.log(error, 'error')
        Toast({
          title: 'Creation Failed!',
          variant: 'error',
          options: { autoClose: 5000 },
        })
        setLoading(false)
      })
  }

  const data = [
    {
      label: 'Add New Advertiser',
      value: 'add-Advertiser',
      desc: () => {
        return (
          <div className="justify-center flex flex-row flex-wrap xl:justify-between mb-6">
            <div className="w-96 space-y-4 my-4">
              <Typography
                placeholder={undefined}
                className="text-2xl font-semibold text-wellBlueGray-900"
              >
                Add New Advertiser
              </Typography>
              <div className="mb-1 flex flex-col">
                <Typography
                  placeholder={undefined}
                  variant="h6"
                  className="mb-1 text-sm font-medium text-wellBlueGray-900"
                >
                  Advertiser ID
                </Typography>
                <Input
                  autoFocus
                  type="text"
                  size="lg"
                  value={advertiserId}
                  placeholder="e.g., 1234567890"
                  className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
                  labelProps={{
                    className: 'hidden',
                  }}
                  style={{ borderRadius: '8px' }}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> }
                  }) => setAdvertiserId(e.target.value)}
                  crossOrigin={undefined}
                />
              </div>
              <div className="mb-1 flex flex-col my-3">
                <Typography
                  placeholder={undefined}
                  variant="h6"
                  className="mb-1 text-sm font-medium text-wellBlueGray-900"
                >
                  Advertiser Name
                </Typography>
                <Input
                  autoFocus
                  type="text"
                  size="lg"
                  value={advertiserName}
                  placeholder="e.g., XYZ Advertising"
                  className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
                  labelProps={{
                    className: 'hidden',
                  }}
                  style={{ borderRadius: '8px' }}
                  onChange={(e) => setAdvertiserName(e.target.value)}
                  crossOrigin={undefined}
                />
              </div>
              <div>
                <Button
                  variant="filled"
                  className="!bg-lstnGreen-500 w-full mt-2 flex items-center justify-center"
                  placeholder={undefined}
                  onClick={handleSubmit}
                  loading={loading}
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      label: 'Infringement Details',
      value: 'infringement-details',
      desc: () => {
        return (
          <div className="justify-center flex flex-row flex-wrap xl:justify-start mb-6 items-center gap-4">
            <div className="my-4">
              <Typography
                placeholder={undefined}
                className="text-2xl font-semibold text-wellBlueGray-900"
              >
                Infringement Details
              </Typography>
              <div className="flex gap-10 my-4">
                <Radio
                  name="type"
                  color="blue"
                  value="image-only"
                  checked={operation === 'image-only'}
                  onChange={handleChange}
                  label={
                    <div>
                      <Typography
                        color="blue-gray"
                        placeholder={undefined}
                        className="font-medium"
                      >
                        Add Screenshot
                      </Typography>
                    </div>
                  }
                  placeholder="undefined"
                  crossOrigin={undefined}
                  className="flex justify-center items-center"
                />
                <Radio
                  name="type"
                  color="blue"
                  label={
                    <div>
                      <Typography
                        color="blue-gray"
                        placeholder={undefined}
                        className="font-medium"
                      >
                        Add Incident & Screenshot
                      </Typography>
                    </div>
                  }
                  value="infringement-with-image-details" // Unique value
                  checked={operation === 'infringement-with-image-details'}
                  onChange={handleChange}
                  placeholder="undefined"
                  crossOrigin={undefined}
                />
                <Radio
                  name="type"
                  color="blue"
                  label={
                    <div>
                      <Typography
                        color="blue-gray"
                        placeholder={undefined}
                        className="font-medium"
                      >
                        Add Ad & Incident & Screenshot
                      </Typography>
                    </div>
                  }
                  value="ad-infringement-with-image" // Unique value
                  checked={operation === 'ad-infringement-with-image'}
                  onChange={handleChange}
                  placeholder="undefined"
                  crossOrigin={undefined}
                />
              </div>
              {operation == 'image-only' ? (
                <>
                  <Typography
                    placeholder={undefined}
                    className="text-base font-normal text-wellBlueGray-900 my-2"
                  >
                    Use this if ad exists, incident exists, and you only want to
                    add a screenshot.
                  </Typography>
                  <div className="flex flex-row flex-wrap items-center gap-6">
                    <div className="w-96 space-y-4">
                      <div className="mb-1 flex flex-col my-3">
                        <Typography
                          placeholder={undefined}
                          variant="h6"
                          className="mb-1 text-sm font-medium text-wellBlueGray-900"
                        >
                          Ad Tracking Url
                        </Typography>
                        <Input
                          autoFocus
                          type="text"
                          size="lg"
                          value={adTrackingUrl}
                          placeholder="e.g., 1234567890"
                          className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
                          labelProps={{
                            className: 'hidden',
                          }}
                          style={{ borderRadius: '8px' }}
                          onChange={(e) => setAdTrackingUrl(e.target.value)}
                          crossOrigin={undefined}
                        />
                      </div>
                      <div className="mb-1 flex flex-col my-3">
                        <Typography
                          placeholder={undefined}
                          variant="h6"
                          className="mb-1 text-sm font-medium text-wellBlueGray-900"
                        >
                          Ad Domain Displayed
                        </Typography>
                        <Input
                          autoFocus
                          type="text"
                          size="lg"
                          value={adDomainDisplayed}
                          placeholder="e.g., 1234567890"
                          className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
                          labelProps={{
                            className: 'hidden',
                          }}
                          style={{ borderRadius: '8px' }}
                          onChange={(e) => setAdDomainDisplayed(e.target.value)}
                          crossOrigin={undefined}
                        />
                      </div>
                      <div className="mb-1 flex flex-col">
                        <Typography
                          placeholder={undefined}
                          variant="h6"
                          className="mb-1 text-sm font-medium text-wellBlueGray-900"
                        >
                          Country
                        </Typography>
                        <Input
                          autoFocus
                          type="text"
                          size="lg"
                          value={country}
                          placeholder="e.g., US or United States"
                          className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
                          labelProps={{
                            className: 'hidden',
                          }}
                          style={{ borderRadius: '8px' }}
                          onChange={(e: {
                            target: { value: React.SetStateAction<string> }
                          }) => setCountry(e.target.value)}
                          crossOrigin={undefined}
                        />
                      </div>
                      <div className="mb-1 flex flex-col my-3">
                        <Typography
                          placeholder={undefined}
                          variant="h6"
                          className="mb-1 text-sm font-medium text-wellBlueGray-900"
                        >
                          Keyword Id
                        </Typography>
                        <Input
                          autoFocus
                          type="text"
                          size="lg"
                          value={keywordId}
                          placeholder="e.g., 1234567890"
                          className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
                          labelProps={{
                            className: 'hidden',
                          }}
                          style={{ borderRadius: '8px' }}
                          onChange={(e) => setKeywordId(e.target.value)}
                          crossOrigin={undefined}
                        />
                      </div>
                      <div className="mb-1 flex flex-col my-3">
                        <Typography
                          placeholder={undefined}
                          variant="h6"
                          className="mb-1 text-sm font-medium text-wellBlueGray-900"
                        >
                          incidentId
                        </Typography>
                        <Input
                          autoFocus
                          type="text"
                          size="lg"
                          value={incidentId}
                          placeholder="e.g., 1234567890"
                          className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
                          labelProps={{
                            className: 'hidden',
                          }}
                          style={{ borderRadius: '8px' }}
                          onChange={(e) => setIncidentId(e.target.value)}
                          crossOrigin={undefined}
                        />
                      </div>
                      <div>
                        {/* Conditional Message */}
                        {keywordId === '' && errorMessage ? (
                          <p className="text-red-700 text-xs font-normal text-start">
                            {errorMessage}
                          </p>
                        ) : (
                          ''
                        )}
                        <Button
                          variant="filled"
                          className="!bg-lstnGreen-500 w-full mt-2 flex items-center justify-center"
                          placeholder={undefined}
                          onClick={updateInfringementDetails}
                          loading={loading}
                          disabled={
                            btnDisabled.country ||
                            btnDisabled.keywordId ||
                            btnDisabled.incidentId
                          }
                        >
                          <span>Save</span>
                        </Button>
                      </div>
                    </div>
                    <div className="flex-col items-center !w-fit md:pl-6 justify-center relative">
                      <Typography
                        className="text-base font-medium text-lstnBlueGray-900 mb-2"
                        placeholder={undefined}
                      >
                        uploaded Images
                      </Typography>
                      <div className="mb-3 flex justify-center">
                        {imageURL ? (
                          <img
                            src={imageURL?.toString() ?? undefined}
                            width="74px"
                            height="74px"
                            className="rounded-full"
                          />
                        ) : (
                          <img src={AvatarLogo} width="74px" height="74px" />
                        )}
                      </div>
                      <div className="flex justify-center">
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          type="file"
                          hidden
                          onChange={
                            handlePictureChange as unknown as ChangeEventHandler<HTMLInputElement>
                          }
                        />

                        <label
                          htmlFor="contained-button-file"
                          className="block"
                        >
                          <Button
                            placeholder={undefined}
                            variant="outlined"
                            size="sm"
                            onClick={handleUploadButtonClick}
                            loading={isLoading ? isLoading : !userData}
                          >
                            {t('uploadButton.uploadAvatar')}
                          </Button>
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              ) : operation == 'infringement-with-image-details' ? (
                <>
                  <Typography
                    placeholder={undefined}
                    className="text-base font-normal text-wellBlueGray-900 my-2"
                  >
                    Use this if ad exists, and you want to add an incident for
                    it plus a supporting screenshot
                  </Typography>
                  <InfringementWithImageDetail />
                </>
              ) : (
                <>
                  <Typography
                    placeholder={undefined}
                    className="text-base font-normal text-wellBlueGray-900 my-2"
                  >
                    Use this if you want to add an incident for an a new ad that
                    does not exist in database.
                    <br /> This adds an ad, an incident, and the supporting
                    screenshot.
                  </Typography>
                  <AdInfringementWithImage />
                </>
              )}
            </div>
          </div>
        )
      },
    },
  ]
  return (
    <div className="flex flex-col pl-4 sm:pl-0 single-menu-container">
      {' '}
      <div className="w-[400px] md:w-[880px] my-5">
        <Typography
          placeholder={undefined}
          className="text-2xl font-medium text-lstnGray-900 my-5"
        >
          Control Panel
        </Typography>
      </div>
      <Tabs value={activeTab}>
        <TabsHeader
          placeholder={undefined}
          className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
          indicatorProps={{
            className:
              'bg-transparent border-b-2 border-well_primary-500 shadow-none rounded-none my-3',
          }}
        >
          {data.map(({ label, value }) => (
            <Tab
              placeholder={undefined}
              key={value}
              value={value}
              onClick={() => setActiveTab(value)}
              className={
                activeTab === value
                  ? 'text-gray-900 font-medium my-3 w-fit'
                  : 'w-fit  my-3'
              }
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody placeholder={undefined}>
          {data.map(({ value, desc }) => (
            <TabPanel key={value} value={value}>
              {desc()}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  )
}

export default AdminPanel
