import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Select, Option, Typography } from '@material-tailwind/react'
import type { SelectProps } from '@material-tailwind/react'
import Tooltip from '../Tooltip/Tooltip'

interface IProps {
  options: Array<{
    value: string
    label: string
    disabled?: boolean
    color?: string
    icon?: any
    infoIcon?: any
    tooltipText?: string
  }>
  selectedValue?: string
  onChange: (value: string) => void
  handleMarkClick?: (e: React.MouseEvent<HTMLElement>) => void
  selectClassName?: string
  placeholder?: string
  menuClassName?: string
}

const Selector: React.FC<IProps & SelectProps> = (props) => {
  const [isLabelHidden, setIsLabelHidden] = useState(false)
  const selectRef = useRef<HTMLDivElement>(null)

  const handleSelectClick = () => {
    console.log(props.selectedValue, 'check')
    setIsLabelHidden(true)
  }
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(e.target as Node)) {
        if (props.selectedValue == '') {
          setIsLabelHidden(false)
        }
      }
    },
    [props.selectedValue],
  )

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <div className={props.className} ref={selectRef}>
      <Select
        onChange={props.onChange}
        animate={{
          mount: { y: 0 },
          unmount: { y: 25 },
        }}
        className={props.selectClassName}
        value={props.selectedValue}
        labelProps={{
          className: isLabelHidden
            ? 'hidden'
            : 'border-none before:border-none',
        }}
        containerProps={{
          className: props.className,
        }}
        label={props.placeholder}
        placeholder={props.placeholder}
        onClick={handleSelectClick}
        menuProps={{
          className: props.menuClassName,
        }}
        data-cy="player-speed-dropdown"
      >
        {props.options &&
          props.options.map((option) => (
            <Option
              key={option.value}
              className="z-10 w-full flex justify-start items-center"
              value={option.value}
              disabled={option.disabled}
              data-cy={`data-project-export-file-type-${option.value}`}
            >
              {option.icon ? (
                <span
                  className={`mr-2 h-6 w-6 ${option.color} rounded-full !flex !justify-center !items-center`}
                  style={{
                    display: 'flex',
                  }}
                >
                  {option.icon}
                </span>
              ) : (
                ''
              )}
              {option.label}
              {option.infoIcon ? (
                <Tooltip
                  content={
                    <div className="w-60">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal opacity-80"
                        placeholder={undefined}
                      >
                        {option.tooltipText}
                      </Typography>
                    </div>
                  }
                  animate={{
                    mount: { scale: 1, y: 0 },
                    unmount: { scale: 0, y: 25 },
                  }}
                  placement="top"
                  className="z-[99999] border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10"
                >
                  <span
                    className={`h-6 w-6 pb-0.5 rounded-full !flex !justify-center !items-center status_icon ${
                      props.selectedValue === option.value
                        ? 'text-blue-gray-800'
                        : 'text-blue-gray-300'
                    }`}
                    style={{
                      display: 'flex',
                    }}
                  >
                    {option.infoIcon}
                  </span>
                </Tooltip>
              ) : (
                ''
              )}
            </Option>
          ))}
      </Select>
    </div>
  )
}

export default Selector
